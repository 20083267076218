import type { FunctionComponent } from 'react'

import { useCheckForm } from '../hook/useForm'
import type { DataKey } from '../../../../../../interfaces/IRegisterBycicle/IRegisterBicycle'
import './styles.scss'

interface Props {
  field: DataKey
}

export const WarningMessage: FunctionComponent<Props> = ({ field }) => {
  const { translateKey } = useCheckForm()

  return (
    <div className="warning-message-container">
      O campo {`${translateKey(field)}`} é obrigatório!
    </div>
  )
}
