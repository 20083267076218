import type { Dispatch, SetStateAction } from 'react'
import { ArrowIconRight } from 'src/components/ui/SidebarService/SidebarServiceArrow'
import { Image } from 'src/components/ui/Image'

import type { EmptyFields } from '../hook/useForm'
import Button from '../../../../../ui/Button'

import './styles.scss'

interface Props {
  fields: EmptyFields[]
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const ModalError: React.FunctionComponent<Props> = ({
  fields,
  isOpen,
  setIsOpen,
}) => {
  return (
    <>
      {isOpen && (
        <section className="modal-error_container">
          <div className="modal-error_content">
            <Button
              className="modal-error-button-close"
              onClick={() => setIsOpen(!isOpen)}
            >
              X
            </Button>
            <div className="icon-error_container-mobile">
              <Image
                src="https://decathlonproqa.vtexassets.com/arquivos/icon-info-error.png"
                alt="icon-error"
                loading="lazy"
                width={52}
                height={54}
              />
            </div>
            <h1>ERRO NO ENVIO DAS INFORMAÇÕES</h1>

            <span>
              Verifique as seguintes informações antes de enviar novamente
            </span>

            {fields.map((item, index) => (
              <p key={index}>- Verifique o Campo: {item.field}</p>
            ))}
            <div className="modal-error-button-link-container">
              <Button
                className="modal-error-button-link"
                onClick={() => setIsOpen(!isOpen)}
              >
                Entendi <ArrowIconRight />
              </Button>
            </div>
            <div className="icon-error_container-desktop">
              <Image
                src="https://decathlonproqa.vtexassets.com/arquivos/icon-info-error.png"
                alt="icon-error"
                loading="lazy"
                width={160}
                height={167}
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ModalError
