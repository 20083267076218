import { FormContextProvider } from './context/formContext'
import RegisterBicycle from './RegisterBicycle'

import './styles.scss'

const MainFormRegisterbicycle = () => {
  return (
    <FormContextProvider>
      <RegisterBicycle />
    </FormContextProvider>
  )
}

export default MainFormRegisterbicycle
