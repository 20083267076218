import type { FunctionComponent } from 'react'
import { Select } from '@faststore/ui'

import { optionsState } from './options'
import './styles.scss'

interface Props {
  changeState: (value: string) => void
}

const CustomSelectState: FunctionComponent<Props> = ({ changeState }) => {
  return (
    <>
      <div>
        <Select
          className="select-container cursor-pointer"
          name="state"
          id="state"
          onChange={(event) => changeState(event.target.value)}
          required
        >
          <option value="select">Selecione</option>
          {optionsState.map((option, index) => (
            <option key={String(index)} value={option.value.name}>
              {option.label}
            </option>
          ))}
        </Select>
      </div>
    </>
  )
}

export default CustomSelectState
