import type { InputBlock, Data } from './IRegisterBicycle'

export const getBottomInputForms = (state: Data): InputBlock[] => [
  {
    inputName: 'brandBike',
    inputId: 'btwin',
    inputKey: 'brandBike',
    classes: {
      div: 'flex items-center pb-5 flex-row-reverse',
      label: 'pr-5',
      input: 'input_radio',
    },
    label: 'BTWIN',
    required: false,
    type: 'radio',
    mask: '',
    value: state.brandBike,
  },
  {
    inputName: 'brandBike',
    inputId: 'rockrider',
    inputKey: 'brandBike',
    classes: {
      div: 'flex items-center pb-5 flex-row-reverse',
      label: 'pr-5',
      input: 'input_radio',
    },
    label: 'ROCKRIDER',
    required: false,
    type: 'radio',
    mask: '',
    value: state.brandBike,
  },
  {
    inputName: 'brandBike',
    inputId: 'riverside',
    inputKey: 'brandBike',
    classes: {
      div: 'flex items-center pb-5 flex-row-reverse',
      label: 'pr-5',
      input: 'input_radio',
    },
    label: 'RIVERSIDE',
    required: false,
    type: 'radio',
    mask: '',
    value: state.brandBike,
  },
  {
    inputName: 'brandBike',
    inputId: 'triban',
    inputKey: 'brandBike',
    classes: {
      div: 'flex items-center pb-5 flex-row-reverse',
      label: 'pr-5',
      input: 'input_radio',
    },
    label: 'TRIBAN',
    required: false,
    type: 'radio',
    mask: '',
    value: state.brandBike,
  },
  {
    inputName: 'date',
    inputId: 'date',
    inputKey: 'orderDate',
    classes: {
      div: 'xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-full xs:w-full w-full',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-date-buy w-full !mb-2.5 p-2',
    },
    label: 'DATA DA COMPRA*',
    required: false,
    type: 'string',
    mask: '99/99/9999',
    value: state.orderDate,
  },
  {
    inputName: 'nf',
    inputId: 'nf',
    inputKey: 'nfNumber',
    classes: {
      div: 'xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-full xs:w-full w-full',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-nf w-full !mb-2.5 p-2',
    },
    label: 'Nº DA NF*',
    required: false,
    type: 'number',
    mask: '',
    value: state.nfNumber,
  },
  {
    inputName: 'bikeModel',
    inputId: 'bikeModel',
    inputKey: 'bikeModel',
    classes: {
      div: 'xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-full xs:w-full w-full',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-quadro-bike w-full !mb-2.5 p-2',
    },
    label: 'MODELO DA BIKE*',
    required: false,
    type: 'text',
    mask: '',
    value: state.bikeModel,
  },
  {
    inputName: 'number-bike',
    inputId: 'number-bike',
    inputKey: 'bikeFrameNumber',
    classes: {
      div: 'xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-full xs:w-full w-full',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-quadro-bike w-full !mb-2.5 p-2',
    },
    label: 'Nº QUADRO DA BIKE*',
    required: false,
    type: 'string',
    mask: '',
    value: state.bikeFrameNumber,
  },
]
