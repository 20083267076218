interface IOption {
  value: { id: string; name: string }
  label: string
}

const optionsState: IOption[] = [
  {
    value: { id: 'AC', name: 'Acre' },
    label: 'AC',
  },
  {
    value: { id: 'AL', name: 'Alagoas' },
    label: 'AL',
  },
  {
    value: { id: 'AP', name: 'Amapá' },
    label: 'AP',
  },
  {
    value: { id: 'AM', name: 'Amazonas' },
    label: 'AM',
  },
  {
    value: { id: 'BA', name: 'Bahia' },
    label: 'BA',
  },
  {
    value: { id: 'CE', name: 'Ceará' },
    label: 'CE',
  },
  {
    value: { id: 'DF', name: 'Distrito Federal' },
    label: 'DF',
  },
  {
    value: { id: 'ES', name: 'Espírito Santo' },
    label: 'ES',
  },
  {
    value: { id: 'GO', name: 'Goiás' },
    label: 'GO',
  },
  {
    value: { id: 'MA', name: 'Maranhão' },
    label: 'MA',
  },
  {
    value: { id: 'MT', name: 'Mato Grosso' },
    label: 'MT',
  },
  {
    value: { id: 'MS', name: 'Mato Grosso do Sul' },
    label: 'MS',
  },
  {
    value: { id: 'MG', name: 'Minas Gerais' },
    label: 'MG',
  },
  {
    value: { id: 'PA', name: 'Pará' },
    label: 'PA',
  },
  {
    value: { id: 'PB', name: 'Paraíba' },
    label: 'PB',
  },
  {
    value: { id: 'PR', name: 'Paraná' },
    label: 'PR',
  },
  {
    value: { id: 'PE', name: 'Pernambuco' },
    label: 'PE',
  },
  {
    value: { id: 'PI', name: 'Piauí' },
    label: 'PI',
  },
  {
    value: { id: 'RJ', name: 'Rio de Janeiro' },
    label: 'RJ',
  },
  {
    value: { id: 'RN', name: 'Rio Grande do Norte' },
    label: 'RN',
  },
  {
    value: { id: 'RS', name: 'Rio Grande do Sul' },
    label: 'RS',
  },
  {
    value: { id: 'RO', name: 'Rondônia' },
    label: 'RO',
  },
  {
    value: { id: 'RR', name: 'Roraima' },
    label: 'RR',
  },
  {
    value: { id: 'SC', name: 'Santa Catarina' },
    label: 'SC',
  },
  {
    value: { id: 'SP', name: 'São Paulo' },
    label: 'SP',
  },
  {
    value: { id: 'SE', name: 'Sergipe' },
    label: 'SE',
  },
  {
    value: { id: 'TO', name: 'Tocantins' },
    label: 'TO',
  },
]

export { optionsState }
