import { useState, useEffect } from 'react'
import { Label, Input } from '@faststore/ui'
import InputMask from 'react-input-mask'

import Button from '../../../../ui/Button'
import { useForm } from './context/formContext'
import { getBottomInputForms } from '../../../../../interfaces/IRegisterBycicle/IBottomInputs'
import { WarningMessage } from './WarningMessage'

import './styles.scss'

const InformationPurchase = () => {
  const { changeState, state, fieldsHasEmpty } = useForm()

  const inputs = getBottomInputForms(state)

  const [stateOtherInputBrandBike, setStateOtherInputBrandBike] = useState({
    disabled: true,
    value: '',
  })

  const [hasSelectOtherBrand, setHasSelectOtherBrand] = useState<boolean>(false)

  useEffect(() => {
    if (hasSelectOtherBrand) {
      const brandsOptionsDiv = document.querySelectorAll<HTMLDivElement>(
        '[data-brand-option]'
      )

      if (brandsOptionsDiv) {
        for (const div of Array.from(brandsOptionsDiv)) {
          const input = div.querySelector<HTMLInputElement>('input')

          if (input) {
            input.checked = false
          }
        }
      }
    }
  }, [hasSelectOtherBrand])

  return (
    <>
      <p className="w-full text-lg text-gray font-bold text-left bg-whisper my-5 mx-0">
        Informações sobre a compra
      </p>
      {/* BICYCLE BRAND */}
      <div className="flex flex-col w-full">
        <div className="flex justify-center xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col">
          <fieldset className="w-full">
            <div className="brands_container flex justify-between xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
              <div className="brands_options flex flex-col w-1/4">
                <p className="mb-1.5 text-gray">MARCA DA SUA BICICLETA*</p>
                <div className="brand_options-content flex justify-between flex-wrap mr-8">
                  {/* BT'WIN */}
                  {/* ROCKRIDER */}
                  {/* RIVERSIDE */}
                  {/* TRIBAN */}
                  {inputs.slice(0, 4).map((input, index) => (
                    <div
                      className={input.classes.div}
                      key={index}
                      data-brand-option
                    >
                      <Label
                        className={input.classes.label}
                        htmlFor={input.inputId}
                      >
                        {input.label}
                      </Label>
                      <Input
                        className={input.classes.input}
                        id={input.inputId}
                        name={input.inputName}
                        type={input.type}
                        onChange={(e) => {
                          // eslint-disable-next-line vtex/prefer-early-return
                          if (e.target.checked) {
                            setStateOtherInputBrandBike({
                              disabled: true,
                              value: '',
                            })
                            setHasSelectOtherBrand(false)
                            changeState(input.inputKey, input.label)
                          }
                        }}
                        required={input.required}
                      />
                    </div>
                  ))}
                  {/* OTHER BRANDS */}
                  <div className="input_radio-other_brands-container w-full flex items-center py-0 gap-3 pt-[5px]">
                    <Input
                      className="input_radio-other_brands cursor-pointer"
                      type="radio"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setStateOtherInputBrandBike({
                            ...stateOtherInputBrandBike,
                            disabled: false,
                          })
                        }

                        setHasSelectOtherBrand(e.target.checked)
                      }}
                      name="bikeBrand"
                      checked={hasSelectOtherBrand}
                    />
                    <Input
                      className="blocks-input-brands w-full text-xs cursor-pointer"
                      type="text"
                      onChange={(e) => {
                        if (!stateOtherInputBrandBike.disabled) {
                          changeState('brandBike', e.target.value)
                          setStateOtherInputBrandBike({
                            ...stateOtherInputBrandBike,
                            value: e.target.value,
                          })
                        }
                      }}
                      disabled={stateOtherInputBrandBike.disabled}
                      placeholder="OUTRAS MARCAS"
                      value={stateOtherInputBrandBike.value}
                    />
                  </div>
                  {fieldsHasEmpty.includes('brandBike') && (
                    <WarningMessage field="brandBike" />
                  )}
                </div>
              </div>
              <div className="xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-full xs:w-full flex items-center justify-between flex-wrap gap-5 xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                {/* DATE OF PURCHASE */}
                {/* NF NUMBER */}
                {/* BIKE MODEL */}
                {/* BIKE FRAME */}
                {inputs.slice(4, inputs.length).map((input, index) => (
                  <div className={input.classes.div} key={index}>
                    <Label
                      className={input.classes.label}
                      htmlFor={input.inputId}
                    >
                      {input.label}
                    </Label>
                    <InputMask
                      className={input.classes.input}
                      mask={input.mask}
                      id={input.inputId}
                      name={input.inputName}
                      type={input.type}
                      onChange={(e) =>
                        changeState(input.inputKey, e.target.value)
                      }
                      required={input.required}
                    />
                    {fieldsHasEmpty.includes(input.inputKey) && (
                      <WarningMessage field={input.inputKey} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </fieldset>
        </div>
        <div className="button_register-container flex items-center justify-center flex-col my-5">
          <Button className="button_register" type="submit">
            Registrar
          </Button>
          {/* <ModalError /> */}
          <p className="my-5 -mx-0 text-lg text-gray text-center">
            As bicicletas das marcas Btwin, Rockrider, Riverside e Triban possui
            &nbsp;
            <b>garantia vitalícia</b>, verifique as condições{' '}
            <a href="/servicos/garantia-vitalicia" target="_blank">
              <span className="underline">clicando aqui</span>
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default InformationPurchase
