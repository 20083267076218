import type { InputBlock, Data } from './IRegisterBicycle'

export const getTopInputForms = (state: Data): InputBlock[] => [
  {
    inputName: 'name',
    inputId: 'name',
    inputKey: 'name',

    classes: {
      div: 'w-full flex flex-col py-0 xl:px-2.5 md:px-2.5 sm:px-0',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-name w-full !mb-2.5 p-2',
    },
    label: 'NOME COMPLETO*',
    required: false,
    type: 'text',
    mask: '',
    value: state.name,
  },
  {
    inputName: 'cpf',
    inputId: 'cpf',
    inputKey: 'cpf',
    classes: {
      div: 'w-full flex flex-col py-0 xl:px-2.5 md:px-2.5 sm:px-0',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-cpf w-full !mb-2.5 p-2',
    },
    label: 'CPF*',
    required: false,
    type: 'string',
    mask: '999.999.999-99',
    value: state.cpf,
  },
  {
    inputName: 'email',
    inputId: 'email',
    inputKey: 'email',
    classes: {
      div: 'w-full flex flex-col py-0 xl:px-2.5 md:px-2.5 sm:px-0',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-email w-full !mb-2.5 p-2',
    },
    label: 'EMAIL*',
    required: false,
    type: 'email',
    mask: '',
    value: state.email,
  },
  {
    inputName: 'phone',
    inputId: 'phone',
    inputKey: 'phone',
    classes: {
      div: 'w-full flex flex-col py-0 xl:px-2.5 md:px-2.5 sm:px-0',
      label: 'text-gray m-0 mx-0 mb-2',
      input: 'blocks-input-tel w-full !mb-2.5 p-2',
    },
    label: 'TELEFONE*',
    required: false,
    type: 'string',
    mask: '(99)99999-9999',
    value: state.phone,
  },
]
