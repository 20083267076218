import type { DataKey } from '../../../../../../interfaces/IRegisterBycicle/IRegisterBicycle'

export interface Fields {
  [key: string]: string | boolean
}

export interface EmptyFields {
  field: string
  original_field: string
}

const translateKey = (key: DataKey) => {
  switch (key) {
    case 'brandBike':
      return 'Marca da bicicleta'

    case 'phone':
      return 'Telefone'

    case 'bikeFrameNumber':
      return 'Número do quadro'

    case 'bikeModel':
      return 'Modelo da bicicleta'

    case 'name':
      return 'Nome'

    case 'orderDate':
      return 'Data da compra'

    case 'nfNumber':
      return 'Número da nota fiscal'

    case 'uf':
      return 'Estado'

    case 'email':
      return 'E-mail'

    case 'cpf':
      return 'CPF'

    default:
      return ''
  }
}

const checkFields = (fields: Fields, ...keysDontCheck: string[]) => {
  let hasEmptyFields = false
  const emptyFields: EmptyFields[] = []

  for (const field of Object.getOwnPropertyNames(fields)) {
    if (keysDontCheck.includes(field)) {
      continue
    }

    const value = fields[field]

    if (!value) {
      hasEmptyFields = true
      emptyFields.push({
        field: translateKey(field as DataKey),
        original_field: field,
      })
    }
  }

  return {
    hasEmptyFields,
    emptyFields,
  }
}

export const useCheckForm = () => ({ checkFields, translateKey })
