/* eslint-disable no-alert */
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useState, useMemo } from 'react'

import type {
  Data,
  DataKey,
} from '../../../../../../interfaces/IRegisterBycicle/IRegisterBicycle'
import ModalError from '../ModalError'
import type { EmptyFields, Fields } from '../hook/useForm'
import { useCheckForm } from '../hook/useForm'

interface ContextValue {
  state: Data
  changeState: (key: DataKey, value: string | boolean) => void
  contextEmptyFields: EmptyFields[]
  handleChangeEmptyFields: (value: EmptyFields[]) => void
  fieldsHasEmpty: string[]
  handleFormSubmit: () => Promise<void>
  handleResetFormState: () => void
}

const FormContext = createContext({} as ContextValue)

const initialState: Data = {
  bikeFrameNumber: '',
  cpf: '',
  email: '',
  bikeModel: '',
  name: '',
  nfNumber: '',
  orderDate: '',
  phone: '',
  uf: '',
  brandBike: '',
  optin: false,
}

export function FormContextProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState<Data>(initialState)
  const [contextEmptyFields, setContextEmptyFields] = useState<EmptyFields[]>(
    []
  )

  const [showModalError, setShowModalError] = useState<boolean>(false)

  const { checkFields } = useCheckForm()

  function changeState(key: DataKey, value: string | boolean) {
    setState({
      ...state,
      [key]: value,
    })
  }

  function handleChangeEmptyFields(value: EmptyFields[]) {
    setContextEmptyFields(value)
  }

  const fieldsHasEmpty = useMemo<string[]>(() => {
    return contextEmptyFields.map((emptyField) => emptyField.original_field)
  }, [contextEmptyFields])

  const handleFormSubmit = async () => {
    const data = state as unknown as Fields
    const { hasEmptyFields, emptyFields } = checkFields(data, 'uf', 'optin')

    if (!hasEmptyFields) {
      window.alert('Cadastro realizado com sucesso')
      const response = await window.fetch('/api/formbycicle', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(state),
      })

      await response.json()
    } else {
      setContextEmptyFields(emptyFields)
      setShowModalError(true)
    }
  }

  const handleResetFormState = () => {
    setState(initialState)
  }

  return (
    <FormContext.Provider
      value={{
        state,
        changeState,
        contextEmptyFields,
        handleChangeEmptyFields,
        fieldsHasEmpty,
        handleFormSubmit,
        handleResetFormState,
      }}
    >
      {children}
      <ModalError
        fields={contextEmptyFields}
        isOpen={showModalError}
        setIsOpen={setShowModalError}
      />
    </FormContext.Provider>
  )
}

export const useForm = () => useContext(FormContext)
