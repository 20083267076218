import { useState } from 'react'
import type { FormEvent } from 'react'
import { Form, Label, Input, Checkbox } from '@faststore/ui'
import InputMask from 'react-input-mask'
import { validateCPF, validatePhone } from 'validations-br'

import { useForm } from './context/formContext'
import CustomSelectState from '../../../../ui/CustomSelectState/CustomSelect'
import InformationPurchase from './InformationPurchase'
import { getTopInputForms } from '../../../../../interfaces/IRegisterBycicle/ITopInputs'
import { WarningMessage } from './WarningMessage/index'

import './styles.scss'

const RegisterBicycle = () => {
  const {
    changeState,
    state,
    fieldsHasEmpty,
    handleFormSubmit,
    handleResetFormState,
  } = useForm()

  const [cpfValid, setCpfValid] = useState<boolean>(false)
  const [phoneValid, setPhoneValid] = useState<boolean>(false)

  const isValidCpf = validateCPF(state.cpf)
  const isValidPhone = validatePhone(state.phone)

  const inputs = getTopInputForms(state)

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    try {
      await handleFormSubmit()
      handleResetFormState()
    } catch (err) {
      console.error('Error submiting form', err)
    }
  }

  return (
    <>
      <section className="register_bicycle-container w-full my-0 mx-auto py-0 px-4">
        <div className="text_top-container">
          {/* TITLE */}
          <h1 className="text-2xl text-gray font-bold text-center">
            REGISTRE SUA BICICLETA
          </h1>
          {/* SUBTITLE */}
          <p className="text-lg text-gray text-center">
            O registro da sua bicicleta é para sua segurança.
          </p>
        </div>
        {/* FORM */}
        <div className="register_bicycle-form_container" />
        <Form
          className="register_bicycle-form_content w-full flex items-center justify-center flex-col my-0 mx-auto"
          onSubmit={handleSubmit}
          method="POST"
          action="/api/formbycicle"
          id="FormRegister"
        >
          <p className="w-full text-lg text-gray font-bold text-left bg-whisper my-5 mx-0">
            Informações pessoais
          </p>
          <div className="blocks xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col w-full flex items-start justify-between">
            {/* NOME COMPLETO */}
            {/* CPF */}
            {inputs.slice(0, 2).map((input, index) => (
              <div
                className={`
                  ${input.classes.div}
                  decathlon-input
                  ${fieldsHasEmpty.includes(input.inputKey) ? 'pb-2' : ''}
                `}
                key={index}
              >
                <Label className={input.classes.label} htmlFor={input.inputId}>
                  {input.label}
                </Label>
                <InputMask
                  mask={input.mask}
                  className={input.classes.input}
                  id={input.inputId}
                  name={input.inputName}
                  type={input.type}
                  onChange={(e) => changeState(input.inputKey, e.target.value)}
                  onBlur={() =>
                    !cpfValid ? setCpfValid(false) : setCpfValid(true)
                  }
                  required={input.required}
                  value={input.value}
                />
                {input.inputKey === 'cpf' && state.cpf.length > 11 && (
                  <span className="w-full">
                    {!isValidCpf ? (
                      <span className="error w-full">CPF inválido</span>
                    ) : (
                      ''
                    )}
                  </span>
                )}

                {fieldsHasEmpty.includes(input.inputKey) && (
                  <WarningMessage field={input.inputKey} />
                )}
              </div>
            ))}
          </div>
          {/* INPUT EMAIL */}
          {/* PHONE */}
          <div className="blocks xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col w-full flex items-start justify-between">
            {inputs.slice(2, inputs.length).map((input, index) => (
              <div
                className={`
                  ${input.classes.div}
                  decathlon-input
                  ${fieldsHasEmpty.includes(input.inputKey) ? 'pb-2' : ''}
                `}
                key={index}
              >
                <Label className={input.classes.label} htmlFor={input.inputId}>
                  {input.label}
                </Label>
                <InputMask
                  mask={input.mask}
                  className={input.classes.input}
                  id={input.inputId}
                  name={input.inputName}
                  type={input.type}
                  onChange={(e) => changeState(input.inputKey, e.target.value)}
                  onBlur={() =>
                    !phoneValid ? setPhoneValid(false) : setPhoneValid(true)
                  }
                  required={input.required}
                  value={input.value}
                />
                {input.inputKey === 'phone' && state.phone.length > 11 && (
                  <span>
                    {!isValidPhone ? (
                      <span className="error w-full">Telefone inválido</span>
                    ) : (
                      ''
                    )}
                  </span>
                )}
                {fieldsHasEmpty.includes(input.inputKey) && (
                  <WarningMessage field={input.inputKey} />
                )}
              </div>
            ))}
            {/* INPUT STATE */}
            <div className="w-full flex flex-col xl:px-2.5 lg:px-2.5 md:px-2.5 sm:px-0 xs: py-0 pb-[10px]">
              <Label className="text-gray m-0 mx-0 mb-2" htmlFor="uf">
                UF
              </Label>
              <CustomSelectState
                changeState={(value) => changeState('uf', value)}
              />
              {fieldsHasEmpty.includes(state.uf) && (
                <WarningMessage field="uf" />
              )}
            </div>
          </div>
          {/* NEWSLETTER */}
          <div className="w-full flex items-center justify-start gap-3 py-0">
            <Checkbox
              className="cursor-pointer"
              checked={state.optin}
              onChange={(e) => {
                changeState('optin', e.target.checked)
              }}
            />
            <Input
              className="text-sm text-gray !pl-0 !bg-white"
              value=" Aceito receber dicas e ofertas da Decathlon"
            />
          </div>
          {/* INFORMATION OF PURCHASE */}
          <InformationPurchase />
        </Form>
      </section>
    </>
  )
}

export default RegisterBicycle
